<template>
  <!--begin::Colleagues-->
  <div class="colleagues-page">
    <h1>Страница пока пуста.</h1>
  </div>
  <!--end::Colleagues-->
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "Colleagues",
  components: {
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Мои коллеги" }]);
  },
  methods: {}
};
</script>
